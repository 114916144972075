import type { NextPage } from 'next'
import Head from 'next/head';
import { CallToAction } from '../components/landing-page/call-to-action';
import Footer from '../components/landing-page/footer';
import { Header } from '../components/landing-page/header';
import { Info } from '../components/landing-page/info';
import { Pricing } from '../components/landing-page/pricing';

const Home: NextPage = () => {
    return (
        <>
            <Head>
                <title>Good Kudos | Slack Integration for Recognition</title>
                <meta name="description"
                    content="Good Kudos | Slack Integration for Recognition"></meta>
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Header hideLogin={false}></Header>
            <Info></Info>
            <CallToAction></CallToAction>
            <Pricing></Pricing>
            <Footer></Footer>
        </>
    )
}

export default Home;
