import { Typography } from "@material-tailwind/react";

export default function Footer() {
    return (
        <footer className="w-full bg-white p-8">
            {/*<div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-white text-center md:justify-between">
                <img className="h-8 w-auto" src="images/logo.svg" alt="" />
                <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
                    <li>
                        <Typography
                            as="a"
                            href="#"
                            className="text-rose-500 font-normal transition-colors hover:text-rose-600 focus:text-blue-500"
                        >
                            Twitter
                        </Typography>
                    </li>
                </ul>
    </div>*/}
            <hr className="my-8 border-blue-gray-50" />
            <Typography className="text-center font-normal text-rose-500 font-semibold">
                <span className="font-normal">&copy;</span> 2023 Good Kudos
            </Typography>
        </footer>
    );
}