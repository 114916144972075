import { CheckIcon } from '@heroicons/react/solid'

const includedFeatures = [
    'Schedule kudos prompts on a weekly basis',
    'Configure kudos qualities to match your values',
    'Know your best people with kudos analytics',
    `Help people get recognised with kudos to DMs`,
]

export const Pricing = () => {
    return (
        <div className="bg-white pt-24 pb-4 sm:pt-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Pricing that works for you</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        We adapt based on who's using the app and feeling recognised
                    </p>
                </div>
                <div>
                    <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Free for up to 8 monthly users</h3>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                A monthly user is counted as any Slack user in a channel that the Good Kudos app is also in
                            </p>
                            <div className="mt-10 flex items-center gap-x-4">
                                <h4 className="flex-none text-sm font-semibold leading-6 text-rose-500">What you can do</h4>
                                <div className="h-px flex-auto bg-gray-100" />
                            </div>
                            <ul
                                role="list"
                                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                            >
                                {includedFeatures.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon className="h-6 w-5 flex-none text-rose-500" aria-hidden="true" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                                <div className="mx-auto max-w-xs px-8">
                                    <p className="text-base font-semibold text-gray-600">For every 4 additional users</p>
                                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                        <span className="text-5xl font-bold tracking-tight text-gray-900">$2</span>
                                        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                                    </p>
                                    <div className="flex" style={{ backgroundSize: 'cover', backgroundPosition: 'center', flexFlow: 'column' }}>
                                        <a href="https://slack.com/oauth/v2/authorize?scope=channels%3Aread%2Cgroups%3Aread%2Cim%3Awrite%2Cchat%3Awrite%2Cusers%3Aread%2Cusers%3Aread.email&amp;user_scope=&amp;redirect_uri=https%3A%2F%2Fgoodkudos.com%2Fapi%2Foauth%2Fslack&amp;client_id=1634080611682.4934820267091"
                                            className="slack-link ml-auto lg:ml-0"
                                            style={{
                                                marginRight: 'auto',
                                                marginTop: '2rem'
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="slack-image"
                                                viewBox="0 0 122.8 122.8">
                                                <path
                                                    d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                                                    fill="#e01e5a"></path>
                                                <path
                                                    d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                                                    fill="#36c5f0"></path>
                                                <path
                                                    d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                                                    fill="#2eb67d"></path>
                                                <path
                                                    d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                                                    fill="#ecb22e"></path>
                                            </svg>
                                            Add to Slack</a>
                                    </div>
                                    <p className="mt-6 text-xs leading-5 text-gray-600">
                                        Invoices and receipts available for easy company reimbursement
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="mt-10 text-base leading-7 text-gray-600 text-center">
                        Are you a charity, a team that is suffering from low morale, or a team that is struggling with the cost of living?
                    </p >
                    <p className="mt-3 text-base leading-7 text-gray-600 text-center">Reach out to us <a className="hover-link text-rose-600" href="mailto:support@goodkudos.com">directly</a> with your story, and we can offer you a discounted rate</p>
                </div>
            </div>
        </div>
    )
}