import { BriefcaseIcon, EmojiSadIcon, FingerPrintIcon, GiftIcon, LockClosedIcon, StarIcon } from '@heroicons/react/outline'

const features = [
    {
        name: 'Retain top talent',
        description:
            'Your employees are your most valuable asset, and losing your top performers is irrecoverable. It is your job to ensure that this does not happen.',
        icon: StarIcon,
    },
    {
        name: '44% want to be valued',
        description:
            'Being valued for their individual contributions is the biggest driver of belonging at work for nearly half of your people.',
        icon: GiftIcon,
        source: "Deloite, May 2020"
    },
    {
        name: '65% would stop job hunting',
        description:
            'There are people in your workforce who are job searching right now. Something as simple as feeling recognized could make them stop.',
        icon: BriefcaseIcon,
        source: "Achievers Workforce Institute, 2022"
    },
    {
        name: '79% are not valued',
        description:
            '4 out of 5 people who leave your team will have not felt recognised or appreciated in the last 6 months. ',
        icon: EmojiSadIcon,
        source: "Achievers Workforce Institute, 2022"
    },
]

export const Info = () => {
    return (
        <div className="bg-lialec py-8 sm:py-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Good kudos at the heart of your business
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        The slack integration for peer to peer recognition and positive feedback
                    </p>
                </div>
                <div className="flex" style={{ backgroundSize: 'cover', backgroundPosition: 'center', flexFlow: 'column' }}>
                    <a href="https://slack.com/oauth/v2/authorize?scope=channels%3Aread%2Cgroups%3Aread%2Cim%3Awrite%2Cchat%3Awrite%2Cusers%3Aread%2Cusers%3Aread.email&amp;user_scope=&amp;redirect_uri=https%3A%2F%2Fgoodkudos.com%2Fapi%2Foauth%2Fslack&amp;client_id=1634080611682.4934820267091"
                        className="slack-link"
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '2rem'
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="slack-image"
                            viewBox="0 0 122.8 122.8">
                            <path
                                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                                fill="#e01e5a"></path>
                            <path
                                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                                fill="#36c5f0"></path>
                            <path
                                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                                fill="#2eb67d"></path>
                            <path
                                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                                fill="#ecb22e"></path>
                        </svg>
                        Add to Slack</a>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-16 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-rose-500">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                                <span className="text-gray-400 text-base mt-2 leading-8" style={{ "fontSize": 10 }}>{feature.source}</span>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}